<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <c-table 
          :url="'CustomerWebService/get_customer_repayments'" 
          :per-page=10 
          :fields="columns" 
          :columns="['firstname', 'lastname', 'register', 'phone', 'amount', 'payment_date']"
          :filename="'Эргэн төлөлт'"
          hover bordered  fixed caption="" 
          :edit_has="true"
          :edit_route="'admin.loan-repayments.new'"
          :edit_params="{}"
        ></c-table>  
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'LoanRepayments.list',
  data: function () {
    return {
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        { label: 'Код', key: 'code' },
        { label: 'Овог', key: 'lastname', sortable: true, sort_key:'lastname' },
        { label: 'Нэр', key: 'firstname', sortable: true, sort_key:'firstname' },
        { label: 'Регистр', key: 'registerNumber', sortable: true, sort_key:'register' },
        { label: 'Утас', key: 'phoneNumber', sortable: true, sort_key:'phone' },
        { label: 'Төлсөн данс', key: 'payAccountNumber' },
        { label: 'Гүйлгээний утга', key: 'payDescription' },
        { label: 'Шилжүүлсэн данс', key: 'accountNumber' },
        { label: 'Шилжүүлсэн банк', key: 'bankName' },
        { label: 'Дүн', key: 'amount', sortable: true, sort_key:'amount', class:"text-right"  },
        { label: 'Төлсөн огноо', key: 'transactionDate', sortable: true, sort_key:'payment_date' },
        { label: 'Хугацаа хэтэрсэн хоног', key: 'overdueDay' }
      ],
    }
  }
}
</script>
